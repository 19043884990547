import { Controller } from "stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["modal"];

  private modalTarget: HTMLElement;
  private modal: Modal;

  connect() {
    this.modal = new Modal(this.modalTarget);
  }

  openModal() {
    this.modal.show();
  }

  closeModal(event) {
    event.preventDefault();
    this.modal.hide();
  }
}
