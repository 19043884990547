// NOTE: this is duplicated from job status explanation component; atm not sure
// if we should promote it to more generic component or not
import { Controller } from "stimulus";
import { Modal, Offcanvas } from "bootstrap";
import { isMobile } from "../../webpack/javascripts/lib/isMobile";

export default class extends Controller {
  static targets = ["modal", "offcanvas"];

  private modalTarget: HTMLElement;
  private offcanvasTarget: HTMLElement;

  private offcanvas: Offcanvas;
  private modal: Modal;

  connect() {
    this.modal = new Modal(this.modalTarget);
    this.offcanvas = new Offcanvas(this.offcanvasTarget);
  }

  showDetails() {
    if (isMobile()) {
      this.offcanvas.show();
    } else {
      this.modal.show();
    }
  }

  closeModal() {
    this.modal.hide();
  }

  closeOffcanvas() {
    this.offcanvas.hide();
  }
}
