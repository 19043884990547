import { Controller } from "stimulus";
import { Fetcher } from "../fetcher";
import { template } from "lodash-es";

export default class extends Controller {
  static targets = ["messageContainer", "inputUid", "inputLegalForm"];

  static values = {
    errorMessage: String,
    successMessage: String,
    url: String,
  };

  messageContainerTarget: HTMLElement;
  inputUidTarget: HTMLInputElement;
  inputLegalFormTarget: HTMLInputElement;
  errorMessageValue: string;
  successMessageValue: string;
  successTemplate: (arg: object) => string;
  urlValue: string;
  fetcher: Fetcher;

  connect() {
    this.fetcher = new Fetcher();
    this.successTemplate = template(this.successMessageValue);
  }

  async click(event: any) {
    if (event.target.value == "" || event.target.value.includes("_")) {
      this.reset();
      return;
    }

    const result = await this.fetch(event.target.value);

    if (!result["company_name"]) {
      this.markAsInvalid();
    } else {
      this.markAsValid(result["company_name"]);
      this.inputLegalFormTarget.value = result["legal_form"];
    }
  }

  private reset() {
    // this.inputTarget.setCustomValidity("");
    this.inputUidTarget.classList.remove("is-invalid");
    this.messageContainerTarget.innerHTML = "";
  }

  private markAsInvalid() {
    // this.inputTarget.setCustomValidity("error");
    this.inputUidTarget.classList.add("is-invalid");
    this.messageContainerTarget.innerHTML = this.errorMessageValue;
  }

  private markAsValid(name: string) {
    this.inputUidTarget.setCustomValidity("");
    this.inputUidTarget.classList.remove("is-invalid");
    this.messageContainerTarget.innerHTML = this.successTemplate({
      company_name: name,
    });
  }

  private async fetch(uid: string): Promise<any> {
    return this.fetcher
      .request(this.urlValue + `?uid=${uid}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return response;
      });
  }
}
