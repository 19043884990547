import { Controller } from "stimulus";

export default class extends Controller {
  showFeedbackForm(event: Event) {
    event.preventDefault();

    this.dispatch("showFeedbackForm")
    this.dispatch("closeBox")
  }

  closeBox() {
    this.dispatch("closeBox")
  }
}
