import { Controller } from "stimulus";
import Bowser from "bowser";
import { Fetcher } from "../../webpack/javascripts/fetcher";
import { serverErrorNotification } from "../../webpack/javascripts/components/notification";
import Uppy from "@uppy/core";
import UppyGermanLocale from "@uppy/locales/lib/de_DE";
import UppyFrenchLocale from "@uppy/locales/lib/fr_FR";
import UppyEnglishLocale from "@uppy/locales/lib/en_US";
import { isMobile } from "../../webpack/javascripts/lib/isMobile";

interface Props {
  allowedFileTypes: string[];
  locale: "en" | "de" | "fr";
  maxFileSize: number;
  dashboardBrowseFiles: string;
  dashboardTitleText: string;
  dashboardTitleTextMobile: string;
  dashboardSublineText: string;
  uploadEndpoint: string;
  showJobEndpoint: string;
}

const localeMap = {
  "en": UppyEnglishLocale,
  "de": UppyGermanLocale,
  "fr": UppyFrenchLocale,
}

export default class extends Controller {
  static values = {
    props: Object,
    type: String,
    token: String,
  };

  static targets = [
    "quoteAmount",
    "amountError",
    "unit",
    "unitError",
    "comment",
    "dashboard",
    "form",
    "submitButton",
  ];

  private quoteAmountTarget: HTMLInputElement;
  private amountErrorTarget: HTMLElement;
  private unitTarget: HTMLInputElement;
  private unitErrorTarget: HTMLElement;
  private commentTarget: HTMLInputElement;
  private dashboardTarget: HTMLElement;
  private formTarget: HTMLFormElement;
  private submitButtonTarget: HTMLButtonElement;

  private height: number;
  private platform: string;
  private plugins: string[];

  private propsValue: Props;
  private typeValue: string;
  private tokenValue: string;

  private uppy: Uppy;

  initialize() {
    this.height = 202;
    this.plugins = ["FileInput"];
    this.platform = Bowser.getParser(
      window.navigator.userAgent,
    ).getPlatform().type;

    if (this.platform !== "desktop") {
      this.height = 300;
    }
  }

  addListenersToImproveMobileBehaviour() {
    const inputs = [this.quoteAmountTarget, this.commentTarget];

    inputs.forEach((input) => {
      input.addEventListener("click", () => {
        if (!this.element.classList.contains("show")) {
          return;
        }

        input.scrollIntoView(true);
        this.element.classList.add("stop-scroll");
      });

      input.addEventListener("blur", () =>
        this.element.classList.remove("stop-scroll"),
      );
    });

    this.element.addEventListener("touchmove", () =>
      this.element.classList.remove("stop-scroll"),
    );
  }

  connect() {
    if (this.platform != "desktop") {
      this.addListenersToImproveMobileBehaviour();
    }

    import("@uppy/dashboard").then((module) => {
      import("../../webpack/javascripts/components/wizard/uppy").then(
        ({ initUppy }) => {
          const title =
            isMobile()
              ? this.propsValue.dashboardTitleTextMobile
              : this.propsValue.dashboardTitleText;

          this.uppy = initUppy(this.propsValue)
            .on("complete", () => this.redirectToJob())
            .use(module.default, {
              hideUploadButton: true,
              inline: true,
              target: this.dashboardTarget,
              showProgressDetails: true,
              height: this.height + 280, // space for the buttons
              plugins: this.plugins,
              proudlyDisplayPoweredByUppy: false,
              showSelectedFiles: true,
              locale: {
                pluralize: localeMap[this.propsValue.locale].pluralize,
                strings: {
                  browseFiles: this.propsValue.dashboardBrowseFiles.replace(
                    /_/g,
                    " ",
                  ),
                  dropPasteFiles: title.replace(/_/g, " "),
                  dropPasteImportFiles: title.replace(/_/g, " "),
                },
              },
            });

          const text = document.createElement("p");
          text.classList.add("grey-60", "dashboard-subline");
          text.innerText = this.propsValue.dashboardSublineText.replace(
            /_/g,
            " ",
          );
          const container = this.element.querySelector(
            ".uppy-Dashboard-AddFiles-title",
          );
          container.appendChild(text);
        },
      );
    });
  }

  get requestBody() {
    const data = {
      quote: {
        unit: this.unitTarget.value,
        quote_amount: this.quoteAmountTarget.value,
        comment: this.commentTarget.value,
      },
    };

    return JSON.stringify(data);
  }

  resetState() {
    const displays = [this.amountErrorTarget, this.unitErrorTarget];

    displays.forEach((display) => {
      if (!display.classList.contains("d-none")) {
        display.classList.add("d-none");
        display.classList.remove("border-red");
      }
    });
  }

  displayError(element, errorElement) {
    errorElement.classList.remove("d-none");
    element.classList.add("border-red");
  }

  validate() {
    let result = true;
    const amount = this.quoteAmountTarget.value;

    if (
      amount === "" ||
      !this.isValidDecimal(amount) ||
      !this.isValidAmount(amount)
    ) {
      this.displayError(this.quoteAmountTarget, this.amountErrorTarget);
      this.quoteAmountTarget.focus();
      result = false;
    }

    if (this.unitTarget.value === "") {
      this.displayError(this.unitTarget, this.unitErrorTarget);
      this.unitTarget.focus();
      result = false;
    }

    return result;
  }

  submit(event: Event) {
    this.resetState();

    if (!this.validate()) {
      event.preventDefault();
      return;
    }

    this.submitButtonTarget.disabled = true;

    const fetcher = new Fetcher({
      form: this.formTarget,
    });
    fetcher
      .request(this.formTarget.action, {
        method: this.queryMethod,
        headers: {
          "Content-Type": "application/json",
        },
        body: this.requestBody,
      })
      .then((result) => {
        // @ts-ignore
        if (result.quoteId) {
          // wtf is this? failed create quote request?
          // @ts-ignore
          this.uppy.setMeta({ upload_parent_id: result.quoteId });
          this.uppy.setMeta({ upload_type: this.typeValue });
          this.uppy.setMeta({ token: this.tokenValue });
          this.uppy.upload();
        } else {
          this.redirectToJob();
        }
      })
      .catch(() => {
        serverErrorNotification();
        this.submitButtonTarget.disabled = false;
      });
  }

  isValidDecimal(amount) {
    // regexp to check if amount is a decimal with a precision of 10 and scale of 2
    const decimalRegexp = /^\d{1,10}(\.\d{1,2})?$/;

    return decimalRegexp.test(amount);
  }

  onEnter(event) {
    event.preventDefault();
    this.submit(event);
  }

  redirectToJob() {
    window.location.href = this.propsValue.showJobEndpoint;
  }

  isValidAmount(amount) {
    const quoteAmount = parseInt(amount);

    return (
      quoteAmount <= parseInt(this.quoteAmountTarget.max) &&
      quoteAmount > parseInt(this.quoteAmountTarget.dataset.minAmount)
    );
  }

  get queryMethod(): string {
    const input = this.formTarget.querySelector(
      'input[name="_method"][type="hidden"]',
    ) as HTMLInputElement;

    // NOTE: fetch method is case sensitive
    if (input) {
      return input.value.toUpperCase();
    } else {
      return this.formTarget.method.toUpperCase();
    }
  }
}
