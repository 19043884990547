import { Controller } from "stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["modal", "reason", "details", "detailsWrapper"];

  modal: Modal;
  modalTarget: HTMLElement;
  reasonTargets: HTMLInputElement[];
  detailsTarget: HTMLInputElement;
  detailsWrapperTarget: HTMLElement;

  connect() {
    this.onChange();
    this.modal = new Modal(this.modalTarget);
  }

  openModal(event) {
    event.preventDefault();
    this.modal.show();
  }

  onChange() {
    if (this.otherReasonSelected) {
      this.detailsWrapperTarget.classList.remove("hidden");
      this.detailsTarget.required = true;
    } else {
      this.detailsWrapperTarget.classList.add("hidden");
      this.detailsTarget.required = false;
    }
  }

  get otherReasonSelected(): boolean {
    return this.selectedValue === "other";
  }

  get selectedValue(): string | null {
    const item = this.reasonTargets.find((item) => item.checked);
    return item ? item.value : null;
  }
}
