import { Controller } from "stimulus";
import intlTelInput from "intl-tel-input";
import { createPhoneInput } from "../components/twilio_lookup";
import { Fetcher } from "../fetcher";
import { Modal } from "bootstrap";
import { template } from "lodash-es";

interface PhoneNumberResponse {
  count: number;
  created_at: number;
  email: string;
  username: string;
}

export default class extends Controller {
  static targets = [
    "phoneField",
    "phoneModal",
    "phoneModalBody",
    "phoneModalYes",
  ];
  static values = {
    employer: Boolean,
    locale: String,
    phoneCountPath: String,
    phoneModalBodyTemplate: String,
    phoneModalYesTemplate: String,
    signInPath: String,
    validationMessage: String, // html5 validation message
  };

  private employerValue: boolean;
  private fetcher: Fetcher;
  private localeValue: string;
  private phoneFieldTarget: HTMLInputElement;
  private phoneModalBodyTarget: HTMLDivElement;
  private phoneModalYesTarget: HTMLButtonElement;
  private phoneModalTarget: HTMLDivElement;
  private phoneInput: ReturnType<typeof intlTelInput>;
  private validationMessageValue: string;
  private phoneCountPathValue: string;
  private phoneModalBodyTemplate: ReturnType<typeof template>;
  private phoneModalBodyTemplateValue: string;
  private phoneModalYesTemplate: ReturnType<typeof template>;
  private phoneModalYesTemplateValue: string;
  private signInPathValue: string;
  private username: string;

  async initialize(): Promise<void> {
    this.phoneInput = await createPhoneInput(this.phoneFieldTarget, "w-100");
    this.phoneModalBodyTemplate = template(this.phoneModalBodyTemplateValue);
    this.phoneModalYesTemplate = template(this.phoneModalYesTemplateValue);
  }

  reset(): void {
    this.phoneFieldTarget.setCustomValidity("");
    this.phoneFieldTarget.classList.remove("is-invalid");
  }

  signIn(): void {
    const data = new FormData();
    data.append("user[login]", this.username);

    this.fetcher
      .request(this.signInPathValue, {
        method: "post",
        body: data,
      })
      .then((json: any) => {
        window.location.href = json.location;
      });
  }

  renderModal(response: PhoneNumberResponse) {
    this.username = response.username;
    const modal = new Modal(this.phoneModalTarget);
    modal.show();
    this.phoneModalBodyTarget.innerHTML = this.phoneModalBodyTemplate({
      created_at: new Date(response.created_at * 1000).toLocaleDateString(
        this.localeValue,
        { month: "long", year: "numeric" },
      ),
    });
    this.phoneModalYesTarget.textContent = this.phoneModalYesTemplate({
      email: response.email,
    });
  }

  setInvalidPhone(): void {
    this.phoneFieldTarget.classList.add("is-invalid");
    this.phoneFieldTarget.setCustomValidity(this.validationMessageValue);
    this.phoneFieldTarget.reportValidity();
  }

  validate(): void {
    // NOTE: some fields might be optional
    if (!this.phoneFieldTarget.required && this.phoneFieldTarget.value === "") {
      this.reset();
      return;
    }

    // NOTE: false indicates that we also accept landlines
    if (!this.phoneInput.isValidNumber(false)) {
      this.setInvalidPhone();
    } else {
      this.reset();
      if (!this.employerValue) return;

      this.fetcher = new Fetcher();
      const data = new FormData();
      data.append("phone_number", this.phoneInput.getNumber());
      data.append("local_format", "true");

      this.fetcher
        .request(this.phoneCountPathValue, {
          method: "post",
          body: data,
        })
        .then((json: any) => {
          switch (json.count) {
            case 0:
            case 3:
            case 4:
              break;
            case 1:
            case 2:
              this.renderModal(json);
              break;
            default:
              this.setInvalidPhone();
          }
        });
    }
  }
}
