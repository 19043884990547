import { Controller } from "stimulus";
import { Popover, Offcanvas } from "bootstrap";
import { isMobile } from "../../webpack/javascripts/lib/isMobile";

export default class extends Controller {
  static targets = ["popover", "trigger", "offcanvas"];

  private popoverTarget: HTMLElement;
  private offcanvasTarget: HTMLElement;
  private triggerTarget: HTMLElement;

  private offcanvas: Offcanvas;
  private popoverInstance: Popover;

  // lazy initialization for both
  // as we also need to handle clicks outside the popover
  private offcanvasInitialized = false;
  private popoverInitialized = false;

  showBox() {
    if (isMobile()) {
      this.initOffcanvas();
      this.offcanvas.toggle();
    } else {
      this.initPopover();
      this.popoverInstance.toggle();
    }
  }

  closeBox() {
    this.closePopover()
    this.closeOffcanvas()
  }

  closePopover() {
    if (this.popoverInitialized) {
      this.popoverInstance.hide();
    }
  }

  closeOffcanvas() {
    if (this.offcanvasInitialized) {
      this.offcanvas.hide();
    }
  }

  initPopover() {
    if (!this.popoverInitialized) {
      this.popoverInstance = new Popover(this.triggerTarget, {
        html: true,
        sanitize: false,
        content: this.popoverTarget,
        placement: "top",
        trigger: "manual"
      });

      // also close the popover when clicking outside of it
      document.addEventListener("click", (event) => {
        // Don't hide if the click was on the trigger element
        if (this.triggerTarget.contains(event.target as Node)) {
          return;
        }

        // NOTE: popover is created dynamically by bootstrap; we can't rely on
        // this.popoverTarget as it will be moved to the popover element
        const popOverElement = document.querySelector(".bs-popover-auto");

        if (popOverElement && !popOverElement.contains(event.target as Node)) {
          this.popoverInstance.hide();
        }
      });

      this.popoverInitialized = true;
    }
  }

  initOffcanvas() {
    if (!this.offcanvasInitialized) {
      this.offcanvas = new Offcanvas(this.offcanvasTarget);
      this.offcanvasInitialized = true;
    }
  }
}
